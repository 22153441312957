import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect, useDispatch } from "react-redux";
import { getUnits } from "client/actions/apiActions";
import PropTypes from "prop-types";
import Loading from "../../layout/Loading";
import Locations from "./Locations";
import Modal from "./Modal";
import "core-js/es6/map";
import "core-js/es6/set";
import UnitModal from "./UnitModal";
import {
  GET_DATA,
  GET_UNIT,
  MARK_EMPTY,
  RESEAT_CUSTOMER,
  RESEND_RECEIPT,
  SAVE_CUSTOMER,
  UPDATE_RECEIPT,
} from "client/actions/types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SocketContext } from "../../../App";
import dayjs from "dayjs";

export const UnitsContext = createContext(null);

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { searchTerm, setSearchTerm } = useContext(SocketContext);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userType = userInfo?.userType;

  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);

  const [hasCartUnits, setHasCartUnits] = useState(false);
  const [cartUnits, updateCartUnits] = useState([]);
  const [checkoutDetails, setCheckoutDetails] = useState({});
  const [hasMoveUnit, setHasMoveUnit] = useState(false);
  const [moveUnit, setMoveUnit] = useState([]);
  const [editUnitModal, setEditUnitModal] = useState(false);
  const [thisUnit, setThisUnit] = useState(0);
  const [selectedDate, setSelectedDate] = useState(dayjs().unix());
  const [unitData, setUnitData] = useState({});
  const [scrollPos, setScrollPos] = useState({
    scrollTop: 0,
    scrollLeft: 0,
    clickedDiv: "",
  });

  const setCartUnits = (data) => {
    updateCartUnits(data);
  };

  const editUmbrella = useCallback(
    (unitId, unitType, unitNumber, unitStatus, unitLocation) => {
      setUnitData({
        unitId,
        unitType,
        unitNumber,
        unitStatus,
        unitLocation,
      });
      setEditUnitModal(true);
      getScrollPos("location" + unitLocation);
    },
    [cartUnits],
  );

  const unSelectUnit = useCallback(
    (unitId) => {
      let newUnits = cartUnits.filter((unit) => unit.unitId !== unitId);
      setCartUnits(newUnits);
      setEditUnitModal(false);
      scrollToPos();
    },
    [cartUnits],
  );

  const selectMultipleUnits = useCallback(
    (data) => {
      setHasCartUnits(true);

      if (data.paymentMethod) {
        setCheckoutDetails({
          paymentMethod: data.paymentMethod,
          hotelLastName: data.hotelLastName,
          hotelManualEntry: data.hotelManualEntry,
          hotelName: data.hotelName,
          hotelRoomNumber: data.hotelRoomNumber,
          guestName: data.guestName,
          wyndhamExtraCharge: data.wyndhamExtraCharge,
          hyattExtraCharge: data.hyattExtraCharge,
        });
      }

      let newUnits = cartUnits;
      newUnits.push({
        ...data.unitData,
        orderTotal: data.orderTotal,
        selectedEquipment: data.selectedEquipment,
        selectedAddOns: data.selectedAddOns,
        retailTotal: data.retailTotal,
        subTotal: data.subTotal,
        addOnsTotal: data.addOnsTotal,
      });

      setCartUnits(newUnits);
      setEditUnitModal(false);
      scrollToPos();
    },
    [cartUnits],
  );

  const moveCustomer = useCallback(
    (customerId, unitId) => {
      setHasMoveUnit(true);
      setEditUnitModal(false);
      scrollToPos();
      setMoveUnit([unitId, customerId]);
    },
    [moveUnit],
  );

  const resetData = useCallback(() => {
    setHasCartUnits(false);
    updateCartUnits([]);
    setHasMoveUnit(false);
    setMoveUnit([]);
    setCheckoutDetails({});
    setUnitData({});
    setThisUnit(0);
    setSearchTerm("");
    setEditUnitModal(false);
  }, [setSearchTerm]);

  const resetState = useCallback(() => {
    resetData();

    // RESET UNIT DATA
    dispatch({
      type: GET_DATA,
      payload: [],
    });
    dispatch({
      type: GET_UNIT,
      payload: [],
    });
    dispatch({
      type: SAVE_CUSTOMER,
      payload: [],
    });
    dispatch({
      type: MARK_EMPTY,
      payload: [],
    });
    dispatch({
      type: RESEAT_CUSTOMER,
      payload: [],
    });
    dispatch({
      type: UPDATE_RECEIPT,
      payload: [],
    });
    dispatch({
      type: RESEND_RECEIPT,
      payload: [],
    });
  }, [setSearchTerm, dispatch]);

  const getScrollPos = useCallback((clickedDiv = "") => {
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (typeof clickedDiv !== "undefined") {
      scrollLeft = document.getElementById(clickedDiv).scrollLeft;
    }
    setScrollPos({ scrollTop, scrollLeft, clickedDiv });
  }, []);

  const scrollToPos = useCallback(() => {
    const { scrollTop, scrollLeft, clickedDiv } = scrollPos;
    if (typeof clickedDiv !== "undefined" && clickedDiv !== "") {
      if (document.getElementById(clickedDiv)) {
        document.getElementById(clickedDiv).scrollLeft = scrollLeft;
      }
    }
    window.scrollTo(scrollLeft, scrollTop);
  }, [scrollPos]);

  const closeModal = useCallback(() => {
    resetState();
    setEditUnitModal(false);
  }, [resetState, scrollToPos]);

  const getUnits = useCallback(
    (date = selectedDate, searchTermUsed = "") => {
      setLoading(true);
      resetState();
      props.getUnits(date, searchTermUsed);
    },
    [props, resetState, selectedDate],
  );

  const onChangeDateClick = (date) => {
    setSelectedDate(date);
    getUnits(date, searchTerm);
  };

  const unSelectMultipleUnits = () => {
    resetState();
  };

  const handleDatePicked = (date) => {
    setSelectedDate(date);
    getUnits(date, searchTerm);
  };

  const unitsContextValue = useMemo(
    () => ({
      hasCartUnits,
      cartUnits,
      checkoutDetails,
      hasMoveUnit,
      moveUnit,
      thisUnit,
      searchTerm,
      editUmbrella,
      selectMultipleUnits,
      unSelectUnit,
      setThisUnit,
      moveCustomer,
      closeModal,
      getUnits,
    }),
    [
      hasCartUnits,
      cartUnits,
      checkoutDetails,
      hasMoveUnit,
      moveUnit,
      thisUnit,
      searchTerm,
      editUmbrella,
      selectMultipleUnits,
      unSelectUnit,
      setThisUnit,
      moveCustomer,
      closeModal,
      getUnits,
    ],
  );

  useEffect(() => {
    setLoading(false);
    if (props.locations) {
      setLocations(props.locations);
    } else if (props.noLocations) {
      setLocations([]);
    }
  }, [props.locations, props.noLocations]);

  useEffect(() => {
    if (props.data) {
      getUnits(selectedDate, searchTerm);
    }
  }, [searchTerm, selectedDate]);

  const dashboardData = props.data;

  if (loading) {
    return <Loading />;
  }

  return (
    <UnitsContext.Provider value={unitsContextValue}>
      <div id="Dashboard" className="container-fluid">
        <br />
        {userType === "A" && (
          <div className="row">
            <div className="col-auto my-2">
              <button
                className="btn btn-warning mr-3"
                onClick={() => {
                  onChangeDateClick(dashboardData.startTime);
                }}
              >
                {dashboardData.startDate}
              </button>
            </div>

            {dashboardData.currentDate && (
              <div className="col-auto my-2">
                <DatePicker
                  className={"form-control"}
                  selected={dayjs(dashboardData.currentDay).toDate()}
                  onChange={(update) => handleDatePicked(dayjs(update).unix())}
                />
              </div>
            )}

            <div className="col-auto my-2">
              <button
                className="btn btn-warning mr-3"
                onClick={() => onChangeDateClick(dashboardData.endTime)}
              >
                {dashboardData.endDate}
              </button>
            </div>

            {hasCartUnits === true && (
              <div className="col-auto my-2">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    unSelectMultipleUnits();
                  }}
                >
                  Cancel Order
                </button>
              </div>
            )}

            <div className="col my-2">&nbsp;</div>
          </div>
        )}
        {userType === "S" && (
          <div className="row">
            <div className="col-12 my-2">
              {(userInfo.DOY - dashboardData.startDOY <= 1 ||
                dashboardData.dom === "1") && (
                <button
                  className="btn btn-warning btn-sm mr-3"
                  onClick={() => {
                    onChangeDateClick(dashboardData.startTime);
                  }}
                >
                  {dashboardData.startDate}
                </button>
              )}

              {(dashboardData.endDOY - userInfo.DOY <= 0 ||
                dashboardData.dom === "1") && (
                <button
                  className="btn btn-warning btn-sm mr-3"
                  onClick={() => {
                    onChangeDateClick(dashboardData.endTime);
                  }}
                >
                  {dashboardData.endDate}
                </button>
              )}

              {hasCartUnits === true && (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    unSelectMultipleUnits();
                  }}
                >
                  Cancel Order
                </button>
              )}
            </div>
          </div>
        )}
        {userType !== "A" && userType !== "S" && hasCartUnits === true && (
          <div className="row">
            <div className="col-12 my-2">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  unSelectMultipleUnits();
                }}
              >
                Cancel Order
              </button>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 my-2">
            <button
              className="btn btn-danger btn-sm"
              onClick={() => {
                getUnits(selectedDate, searchTerm);
              }}
            >
              Refresh Units
            </button>
            {/*&nbsp; &nbsp; Current Time : {currentTime}*/}
          </div>
        </div>
        {searchTerm && (
          <div className="row">
            <div className="col-12 my-2 text-center">
              <h2>Searching for : {searchTerm}</h2>
              <h4>{dashboardData.totalUnits} results found</h4>
              <button
                className="btn btn-danger btn-lg m-1"
                onClick={() => setSearchTerm("")}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
        )}
      </div>

      <Locations locations={locations} scrollToPos={scrollToPos} />

      {editUnitModal && (
        <Modal>
          <UnitModal
            loadUnitData={unitData}
            closeModal={closeModal}
            resetState={resetState}
            updateUnitInfo={getUnits}
            selectedDate={selectedDate}
          />
        </Modal>
      )}
    </UnitsContext.Provider>
  );
};

Dashboard.propTypes = {
  data: PropTypes.object.isRequired,
  locations: PropTypes.array,
};

const mapStateToProps = (state) => ({
  data: state.units.data,
  locations: state.units.data.locations,
  noLocations: state.units.data.noLocations,
});

export default connect(mapStateToProps, {
  getUnits,
})(Dashboard);
