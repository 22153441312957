import React, { useContext, useEffect, useState } from "react";
import { UnitPurchaseContext } from "../../UnitModal";
import { connect, useDispatch } from "react-redux";
import {
  reseatCustomer,
  resendReceipt,
  updateReceipt,
  confirmCustomer,
  logAction,
} from "client/actions/apiActions";
import SelectReceiptType from "../TabNewCustomer/SelectReceiptType";
import { RESEND_RECEIPT, UPDATE_RECEIPT } from "client/actions/types";
import { UnitsContext } from "../../Dashboard";
import ConfirmAlert from "../../../../layout/ConfirmAlert";
import Modal from "../../Modal";

const Customer = (props) => {
  const [sendingReceipt, setSendingReceipt] = useState(false);
  const [showEditReceipt, setShowEditReceipt] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showResendReceipt] = useState(true);
  const dispatch = useDispatch();
  const [reseating, setReseating] = useState(false);
  const [confirmMoveCustomer, setConfirmMoveCustomer] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const UnitsData = useContext(UnitsContext);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const unitId =
    UnitPurchaseData.unitData.locationsId +
    "_" +
    UnitPurchaseData.unitData.umbrellaRow +
    "_" +
    UnitPurchaseData.unitData.umbrellaPosition +
    "_" +
    UnitPurchaseData.unitData.umbrellaNumber;

  const {
    id,
    receiptType,
    receiptPhone,
    receiptEmail,
    customerName,
    epoc,
    paymentMethod,
    price,
  } = props.customer;

  const editReceipt = () => {
    props.logAction(
      unitId,
      "Edit Receipt : '" +
        customerName +
        "' : '" +
        receiptType +
        ", " +
        receiptPhone +
        ", " +
        receiptEmail +
        "'",
    );
    setShowEditReceipt(true);
  };

  const updateReceipt = () => {
    setUpdating(true);
    if (!updating) {
      props.logAction(
        unitId,
        "Edit Receipt saved : '" +
          customerName +
          "' : '" +
          receiptType +
          ", " +
          receiptPhone +
          ", " +
          receiptEmail +
          "'",
      );
      props.updateReceipt(
        unitId,
        id,
        receiptType,
        receiptPhone,
        receiptEmail,
        UPDATE_RECEIPT,
      );
      setShowEditReceipt(false);
      setUpdating(false);
    }
  };

  const resendReceipt = () => {
    setSendingReceipt(true);
    props.logAction(unitId, "Receipt resent : '" + customerName + "'");
    props.resendReceipt(id);
  };

  const reseatCustomer = () => {
    setReseating(true);
    props.logAction(unitId, "Customer reseated : '" + customerName + "'");
    props.reseatCustomer({
      id,
      unitId: UnitPurchaseData.unitData.id,
    });
  };

  const moveCustomer = () => {
    props.logAction(unitId, "Clicked move customer : '" + customerName + "'");
    setConfirmMoveCustomer(true);
  };

  const confirmCustomer = () => {
    props.logAction(unitId, "Customer confirmed : '" + customerName + "'");
    props.confirmCustomer(props.customer.id);
  };

  useEffect(() => {
    if (props?.receiptData?.pageName === "resendReceipt") {
      dispatch({
        type: RESEND_RECEIPT,
        payload: [],
      });
      setSendingReceipt(false);
    }
  }, [props?.receiptData]);

  useEffect(() => {
    if (props?.data.pageName === "confirmCustomer") {
      if (props.data.success) {
        UnitPurchaseData.getUnits();
        UnitPurchaseData.closeModal();
      }
    }
  }, [props.data]);

  const viewReceipt = () => {
    props.logAction(
      unitId,
      "View Receipt : '" +
        customerName +
        "' : '" +
        receiptType +
        ", " +
        receiptPhone +
        ", " +
        receiptEmail +
        "'",
    );

    // open modal with receipt data
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {confirmMoveCustomer && (
        <ConfirmAlert
          title="Move Customer"
          message="Are you sure you want to move this customer?"
          buttons={[
            {
              label: "Yes",
              className: "btn btn-success",
              onClick: () => {
                props.logAction(
                  unitId,
                  "Move confirmed : '" + customerName + "'",
                );
                UnitsData.moveCustomer(id, unitId);
                setConfirmMoveCustomer(false);
              },
            },
            {
              label: "No",
              className: "btn btn-danger",
              onClick: () => {
                props.logAction(
                  unitId,
                  "Move cancelled : '" + customerName + "'",
                );
                setConfirmMoveCustomer(false);
              },
            },
          ]}
        />
      )}

      <tr>
        <td>{epoc}</td>
        <td>{customerName}</td>
        <td>{paymentMethod}</td>
        <td>{price}</td>
        <td>
          {(userInfo.userType === "A" ||
            userInfo.userType === "S" ||
            userInfo.userType === "M") && (
            <>
              {UnitPurchaseData.unitData.status !== "4" &&
                UnitPurchaseData.unitData.status !== "2" && (
                  <button
                    className="btn btn-secondary btn-sm me-1"
                    onClick={reseatCustomer}
                    disabled={reseating}
                  >
                    {reseating && <i className="fas fa-spinner fa-spin" />}
                    <i className="fas fa-umbrella-beach" /> Re-Seat
                  </button>
                )}

              {receiptType !== "" && showResendReceipt && !sendingReceipt && (
                <>
                  {showResendReceipt && !sendingReceipt ? (
                    <button
                      className="btn btn-secondary btn-sm me-1"
                      onClick={resendReceipt}
                    >
                      <i className="fas fa-paper-plane" />
                    </button>
                  ) : (
                    <button className="btn btn-warning btn-sm me-1" disabled>
                      <i className="fas fa-spinner fa-spin" />
                    </button>
                  )}
                </>
              )}
              {receiptType !== "" && (
                <>
                  <button
                    className="btn btn-secondary btn-sm me-1"
                    onClick={viewReceipt}
                  >
                    <i className="fas fa-eye" />
                  </button>
                  <button
                    className="btn btn-secondary btn-sm me-1"
                    onClick={editReceipt}
                    disabled={showEditReceipt}
                  >
                    <i className="fas fa-pencil-alt" />
                  </button>
                </>
              )}

              <button
                className="btn btn-warning btn-sm me-1"
                onClick={moveCustomer}
              >
                <i className="fas fa-arrows-alt" />
              </button>
            </>
          )}
          <button
            className="btn btn-warning btn-sm me-1"
            onClick={confirmCustomer}
          >
            <i className="fas fa-check" />
          </button>

          {showModal && (
            <Modal className="text-white">
              <pre>{props.customer.notes}</pre>
              <button className="btn btn-danger" onClick={closeModal}>
                Close
              </button>
            </Modal>
          )}
        </td>
      </tr>
      {showEditReceipt && (
        <tr>
          <td colSpan={5}>
            <div className="container">
              <SelectReceiptType
                receiptType={receiptType}
                receiptPhone={receiptPhone}
                receiptEmail={receiptEmail}
                editMode={true}
                updateReceiptType={updateReceipt}
                setShowEditReceipt={setShowEditReceipt}
              />
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
  receiptData: state.units.receiptData,
});

export default connect(mapStateToProps, {
  reseatCustomer,
  resendReceipt,
  updateReceipt,
  confirmCustomer,
  logAction,
})(Customer);
