import React, { Fragment, useContext } from "react";
import { UnitsContext } from "./Dashboard";

const Unit = (props) => {
  const { unit, index, locationId, searchTerm, extraStyle } = props;
  const {
    id,
    type,
    number,
    status,
    totalPrice,
    unitTime,
    multiUnit,
    customerConfirmed,
    notes,
  } = unit;

  const UnitsData = useContext(UnitsContext);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userType = userInfo.userType;

  const editUmbrella = UnitsData.editUmbrella;

  let unitStatus = status;
  if (number) {
    const unitsFound = UnitsData.cartUnits.filter(
      (filterUnit) =>
        filterUnit.locationsId === locationId &&
        filterUnit.umbrellaType === type &&
        filterUnit.umbrellaNumber === number,
    );
    if (unitsFound.length > 0) {
      unitStatus = 10;
    }
  }

  if (unitStatus !== 0) {
    if (unit.id + "_" + number === UnitsData.moveUnit[0]) {
      unitStatus = 9;
    }
  }

  let unitClass;
  let unitNumber = `(${index + 1})`;
  let unitTotal = null;
  let unitOnClick = null;

  switch (unitStatus) {
    // Empty Unit
    case 1:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY > 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitNumber = `${type}${number}`;
      unitClass = "bg-success text-light border-success";
      break;

    // rented
    case 2:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY > 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitClass = "bg-danger text-light border-danger";
      unitNumber = `${type}${number}`;
      unitTotal = totalPrice !== 0 ? `$${totalPrice}` : "";
      break;

    // Empty Unit
    case 3:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY > 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitClass = "bg-success text-light border-success";
      unitNumber = `${type}${number}`;
      unitTotal = totalPrice !== 0 ? `$${totalPrice}` : "";
      break;

    // re-rented
    case 4:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY > 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitClass = "bg-primary text-light border-primary";
      unitNumber = `${type}${number}`;
      unitTotal = totalPrice !== 0 ? `$${totalPrice}` : "";
      break;

    // OCCUPIED
    case 5:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY > 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitTotal = totalPrice !== 0 ? `$${totalPrice}` : "";
      unitNumber = `${type}${number}`;
      unitClass = "bg-occupied text-light border-occupied";
      break;

    // TOWER / PIER
    case 6:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY < 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitClass = "bg-dark text-light border-dark";
      if (type === "P") {
        unitNumber = "Pier";
      }
      if (type === "T") {
        unitNumber = `Tower\n${number}`;
      }
      break;

    // FLASHING
    case 7:
      if (
        userType === "S" &&
        props.currentDOY - props.startDOY > 1 &&
        props.dom !== "1"
      ) {
      } else {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitClass = "bg-occupied-flashing text-light border-occupied";
      unitNumber = `${type}${number}`;
      unitTotal = totalPrice !== 0 ? `$${totalPrice}` : null;
      break;

    // MOVE CLIENT
    case 9:
      unitClass = "bg-danger moveUnit py-2 text-light border-danger";
      unitNumber = `${type}${number}`;
      break;

    // IN CART
    case 10:
      unitClass = "bg-success inCart text-light border-success";
      unitOnClick = () => {
        editUmbrella(id, type, number, status, locationId);
        UnitsData.setThisUnit(id);
      };
      break;

    default:
      if (searchTerm === "" || typeof searchTerm === "undefined") {
        unitOnClick = () => {
          editUmbrella(id, type, number, status, locationId);
        };
      }
      unitClass = "bg-light text-secondary border-light";
      break;
  }

  return (
    <div
      id={id}
      style={{ float: "left", width: 108, height: 170 }}
      className={`${unitClass} d-flex flex-row rounded border m-1 ${extraStyle}`}
      onClick={unitOnClick}
    >
      <div className="d-flex flex-column flex-grow-1 pb-2">
        <div className="text-center fs-2 my-1">
          {unitNumber ? unitNumber : <>&nbsp;</>}
        </div>
        <div className="text-center fs-4 my-1">
          {unitTime ? unitTime : <>&nbsp;</>}
        </div>
        <div className="text-center fs-3 mt-1">
          {unitTotal ? unitTotal : <>&nbsp;</>}
        </div>
      </div>
      <div
        className="d-flex flex-column border-1 border-start py-2n px-1"
        style={{ width: 30 }}
      >
        <div className="flex-grow-1 text-center fs-5">
          {multiUnit ? "M" : <>&nbsp;</>}
        </div>
        <div className="flex-grow-1 text-center fs-5">
          {customerConfirmed ? <i className="fas fa-check" /> : <>&nbsp;</>}
        </div>
        <div className="flex-grow-1 text-center fs-5">
          {notes ? "N" : <>&nbsp;</>}
        </div>
      </div>
    </div>
  );
};

export default Unit;
