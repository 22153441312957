import React from "react";
import Dashboard from "./Dashboard";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

const Search = (props) => {
  const { searchTerm } = useParams();

  return <>{searchTerm && <Dashboard {...props} searchTerm={searchTerm} />}</>;
};

Search.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Search);
