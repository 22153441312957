import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveUnitNote } from "client/actions/apiActions";

import { UnitPurchaseContext } from "../UnitModal";

const TabNotes = (props) => {
  const [customNotes, setCustomNotes] = useState("");
  const [saving, setSaving] = useState(false);
  const UnitsData = useContext(UnitPurchaseContext);
  const notes = UnitsData.unitData.notes;
  const unitId = UnitsData.unitId;

  const UnitPurchaseData = useContext(UnitPurchaseContext);

  const onChange = (e) => setCustomNotes(e.target.value);
  const onSaveNotes = () => {
    props.saveUnitNote(unitId, customNotes);
    setCustomNotes("");
    setSaving(true);
  };

  useEffect(() => {
    setSaving(false);
    if (props.data?.pageName === "saveNotes" && props.data?.success) {
      UnitPurchaseData.getUnits();
      UnitPurchaseData.closeModal();
    }
  }, [props.data]);

  return (
    <div className="card panel-primary">
      <div className="card-header">Unit Notes</div>
      <div className="card-body">
        {notes && (
          <table className="mt-3 table table-striped">
            <thead className="table-dark text-light">
              <tr>
                <th width="10%">User</th>
                <th width="10%">Date</th>
                <th width="80%">Note</th>
              </tr>
            </thead>
            <tbody>
              {notes.map((note, index) => (
                <tr key={index}>
                  <td>{note.user}</td>
                  <td>{note.epoc}</td>
                  <td>{note.note.replace("LOG:", "")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className="row">
          <div className="col-12">
            <h3>Enter Custom Notes</h3>
          </div>
          <div className="col-12">
            <textarea
              className="form-control border-secondary"
              name="customNotes"
              value={customNotes}
              onChange={onChange}
            />
          </div>
          <div className="col-12">
            <button
              className="btn btn-success my-2"
              onClick={onSaveNotes}
              disabled={saving}
            >
              Save Note
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  saveUnitNote,
})(TabNotes);
