import React, { useContext, useEffect, useState } from "react";
import { UnitPurchaseContext } from "../../UnitModal";
import { connect } from "react-redux";
import {
  markOccupiedEmpty,
  markOccupied,
  markOccupiedFlashing,
  logAction,
} from "client/actions/apiActions";
import { SocketContext } from "../../../../../App";

const SelectPaymentMethod = (props) => {
  const UnitsData = useContext(UnitPurchaseContext);
  const unitStatus = parseInt(UnitsData.unitData.status);
  const unitId = UnitsData.unitId;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userType = userInfo.userType;
  const [updating, setUpdating] = useState(false);
  const socketContext = useContext(SocketContext);
  const { socket } = socketContext;

  let pmtCash = true;
  let pmtRC = true;
  let pmtCC = true;
  let pmtVC = true;

  // Steps
  // 1. Select Payment Method
  // 2. Select Equipment

  const selectPaymentMethod = (paymentMethod) => {
    UnitsData.setPaymentMethod(paymentMethod);
    if (paymentMethod === "Room Charge") {
      UnitsData.setUnitStep("Select Hotel");
    } else {
      UnitsData.setUnitStep("Select Equipment");
    }
  };
  const markEmpty = () => {
    setUpdating(true);
    props.markOccupiedEmpty({ unitId: unitId });
  };
  const markOccupied = () => {
    setUpdating(true);
    props.markOccupied({ unitId: unitId });
  };
  const markFlashing = () => {
    setUpdating(true);
    let data = {
      location: UnitsData.unitData.locationsId,
      locationName: UnitsData.unitData.umbrellaLocation,
      unit: UnitsData.unitData.umbrellaType + UnitsData.unitData.umbrellaNumber,
    };
    console.log("react markFlashing", data);
    socket.emit("unit:flashing", data);
    props.markOccupiedFlashing({ unitId: UnitsData.unitData.id });
  };

  useEffect(() => {
    setUpdating(false);
    if (props.occupiedData?.status === true) {
      UnitsData.getUnits();
      UnitsData.closeModal();
    }
  }, [props.occupiedData]);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3 text-center">
          <div className="d-grid gap-2">
            {pmtCC === true && (
              <button
                className="btn btn-block btn-secondary btn-lg my-1"
                onClick={() => selectPaymentMethod("Credit Card")}
                disabled={updating}
              >
                CREDIT CARD
              </button>
            )}
            {pmtRC === true && (
              <button
                className="btn btn-block btn-secondary btn-lg my-1"
                onClick={() => selectPaymentMethod("Room Charge")}
                disabled={updating}
              >
                HOTEL CHARGE
              </button>
            )}
            {pmtVC === true && (
              <button
                className="btn btn-block btn-secondary btn-lg my-1"
                onClick={() => selectPaymentMethod("Voucher")}
                disabled={updating}
              >
                VOUCHER
              </button>
            )}
            {pmtCash === true && (
              <button
                className="btn btn-block btn-secondary btn-lg my-5"
                onClick={() => selectPaymentMethod("Cash")}
                disabled={updating}
              >
                PIER 60 / BEACH HOUSE
              </button>
            )}
          </div>
          {(unitStatus === 5 || unitStatus === 7) && (
            <div className="row">
              {unitStatus === 5 && (
                <>
                  <div
                    className={
                      userType !== "A" && userType !== "S" && userType !== "M"
                        ? "col-12"
                        : "col-6"
                    }
                  >
                    <button
                      className="btn btn-block btn-primary btn-lg"
                      onClick={markEmpty}
                      disabled={updating}
                    >
                      {updating && <i className="fas fa-spinner fa-spin" />}{" "}
                      MARK EMPTY
                    </button>
                  </div>
                  {(userType === "A" ||
                    userType === "S" ||
                    userType === "M") && (
                    <div className="col-6">
                      <button
                        className="btn btn-block btn-occupied-flashing btn-lg"
                        onClick={markFlashing}
                        disabled={updating}
                      >
                        {updating && <i className="fas fa-spinner fa-spin" />}{" "}
                        MARK Flashing
                      </button>
                    </div>
                  )}
                </>
              )}
              {unitStatus === 7 &&
                (userType === "A" || userType === "S" || userType === "M") && (
                  <div className="col-12">
                    <button
                      className="btn btn-block btn-primary btn-lg"
                      onClick={markEmpty}
                      disabled={updating}
                    >
                      {updating && <i className="fas fa-spinner fa-spin" />}{" "}
                      MARK EMPTY
                    </button>
                  </div>
                )}
            </div>
          )}
          {unitStatus !== 5 && unitStatus !== 7 && (
            <div className="row">
              <div
                className={
                  userType !== "A" && userType !== "S" && userType !== "M"
                    ? "col-12"
                    : "col-6"
                }
              >
                <div className="d-grid gap-2">
                  <button
                    className="btn btn-block btn-occupied btn-lg"
                    onClick={markOccupied}
                    disabled={updating}
                  >
                    {updating && <i className="fas fa-spinner fa-spin" />} MARK
                    OCCUPIED
                  </button>
                </div>
              </div>
              <div className="col-6">
                {(userType === "A" || userType === "S" || userType === "M") && (
                  <div className="d-grid gap-2">
                    <button
                      className="btn btn-block btn-occupied-flashing btn-lg"
                      onClick={markFlashing}
                      disabled={updating}
                    >
                      {updating && <i className="fas fa-spinner fa-spin" />}{" "}
                      MARK FLASHING
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  occupiedData: state.units.unitData,
});

export default connect(mapStateToProps, {
  logAction,
  markOccupiedEmpty,
  markOccupied,
  markOccupiedFlashing,
})(SelectPaymentMethod);
