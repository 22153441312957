import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getUnit, tokenConfig, logAction } from "client/actions/apiActions";
import { UnitPurchaseContext } from "../UnitModal";
import Customer from "./TabCustomerList/Customer";
import { RESEND_RECEIPT, UPDATE_RECEIPT } from "client/actions/types";
import axios from "axios";
import { ApiURL } from "../../../../config";

const TabCustomerList = (props) => {
  const UnitPurchaseData = useContext(UnitPurchaseContext);
  const unitId =
    UnitPurchaseData?.unitData?.locationsId +
    "_" +
    UnitPurchaseData?.unitData?.umbrellaRow +
    "_" +
    UnitPurchaseData?.unitData?.umbrellaPosition;
  const customers = UnitPurchaseData.unitData.customers;
  const [updating, setUpdating] = useState(false);
  const [sendingReceipt, setSendingReceipt] = useState(false);
  const dispatch = useDispatch();
  const [markingEmpty, setMarkingEmpty] = useState(false);

  const markUnitEmpty = () => {
    setMarkingEmpty(true);
    markEmpty({ unitId: UnitPurchaseData.unitData.id });
  };

  const updateReceipt = (
    unitId,
    id,
    receiptType,
    customerPhone,
    customerEmail,
  ) => {
    setUpdating(true);
    if (!updating) {
      props.updateReceipt(
        unitId,
        id,
        receiptType,
        customerPhone,
        customerEmail,
      );
    }
  };

  const markEmpty = async (unitId) => {
    const res = await axios.post(
      `${ApiURL}/markEmpty`,
      { unitData: unitId },
      tokenConfig(),
    );
    if (res.status) {
      UnitPurchaseData.getUnits();
      UnitPurchaseData.closeModal();
    }
  };

  useEffect(() => {
    if (props?.receiptData?.pageName === "resendReceipt") {
      dispatch({
        type: RESEND_RECEIPT,
        payload: [],
      });
      setSendingReceipt(false);
    }
    if (props?.receiptData?.pageName === "updateReceipt") {
      dispatch({
        type: UPDATE_RECEIPT,
        payload: [],
      });
      setUpdating(false);
      props.getUnit(unitId);
    }
  }, [props?.receiptData]);

  useEffect(() => {
    if (props.data?.pageName === "reseatCustomer") {
      if (props.data.status) {
        UnitPurchaseData.getUnits();
        UnitPurchaseData.closeModal();
      }
    }
  }, [props.data]);

  useEffect(() => {
    console.log("props.data", props.unitData);
    if (props.unitData.length !== 0) {
      props.logAction(unitId, "Loaded customer list screen '");
    }
  }, []);

  return (
    <div className="card card-primary">
      <div className="card-header">Customers</div>
      <div className="card-body">
        <table className="table table-striped">
          <thead>
            <tr className="bg-dark text-light">
              <th>Time</th>
              <th>Name</th>
              <th>Payment</th>
              <th>Price</th>
              <th>Tools</th>
            </tr>
          </thead>
          <tbody>
            {customers &&
              customers.map((customer, index) => {
                console.log("customer", customer);
                return (
                  <Customer
                    key={index}
                    customer={customer}
                    getUnit={props.getUnit}
                    updateReceipt={updateReceipt}
                    sendingReceipt={sendingReceipt}
                    updating={updating}
                  />
                );
              })}
          </tbody>
        </table>

        {(UnitPurchaseData.unitData.status === "2" ||
          UnitPurchaseData.unitData.status === 2 ||
          UnitPurchaseData.unitData.status === "4" ||
          UnitPurchaseData.unitData.status === 4) && (
          <button
            type="submit"
            className="btn btn-primary"
            onClick={markUnitEmpty}
            disabled={markingEmpty}
          >
            {markingEmpty && <i className="fas fa-spinner fa-spin" />} Mark
            Empty
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  all: state,
  data: state.pages.data,
  units: state.units,
  unitData: state.units.unitData,
  receiptData: state.units.receiptData,
});

export default connect(mapStateToProps, {
  getUnit,
  logAction,
})(TabCustomerList);
