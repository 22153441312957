import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getDropoffReport,
  saveDropoffNotes,
  getPrintNotes,
} from "client/actions/apiActions";

class NotesDropOff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropoffCash: "",
      dropoffNotes: "",
      errorMessage: null,
      initialDataLoaded: false,
    };
  }
  componentDidMount() {
    this.props.getDropoffReport();
  }
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { initialDataLoaded } = this.state;
    if (
      initialDataLoaded === false &&
      typeof nextProps.dropoffReport.overview !== "undefined"
    ) {
      this.setState({ initialDataLoaded: true, data: nextProps.dropoffReport });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  saveNotes = () => {
    const { dropoffCash, dropoffNotes } = this.state;
    if (dropoffCash === 0 || dropoffCash === null || dropoffCash === "") {
      this.setState({ errorMessage: "Enter a dropoff amount!" });
    } else {
      this.setState({ errorMessage: "" });
      this.props.saveDropoffNotes(dropoffCash, dropoffNotes);
      this.setState({ dropoffCash: "", dropoffNotes: "" });
    }
  };

  printNotes = () => {
    this.props.getPrintNotes();
  };

  render() {
    const { dropoffReport } = this.props;
    const { dropoffCash, dropoffNotes, errorMessage } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <h1>Today's Total</h1>
            {dropoffReport.overview && (
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Today's Total</td>
                    <td>$ {dropoffReport.overview.todayTotal}</td>
                  </tr>
                  <tr>
                    <td>CC Total</td>
                    <td>$ {dropoffReport.overview.ccTotal}</td>
                  </tr>
                  <tr>
                    <td>Cash Total</td>
                    <td>$ {dropoffReport.overview.cashTotal}</td>
                  </tr>
                  <tr>
                    <td>Room Charge Total</td>
                    <td>$ {dropoffReport.overview.roomChargeTotal}</td>
                  </tr>
                </tbody>
              </table>
            )}
            <hr noshade="true" />
            <h2>Notes and Drop Off</h2>
            {errorMessage && (
              <div className="alert alert-danger" role="alert">
                <b>ERROR:</b> <span className="ErrorText">{errorMessage}</span>
              </div>
            )}

            <table className="table table-striped">
              <tbody>
                <tr>
                  <td>Drop Off Cash Amount</td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      id="dropoffCash"
                      name="dropoffCash"
                      placeholder="$"
                      value={dropoffCash}
                      onChange={this.onChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <textarea
                      rows="3"
                      cols="10"
                      className="form-control"
                      placeholder="Notes"
                      id="dropoffNotes"
                      name="dropoffNotes"
                      onChange={this.onChange}
                      value={dropoffNotes}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 offset-sm-3 col-md-6 offset-md-3 text-center">
            <button
              type="submit"
              className="btn btn-secondary btn-block"
              onClick={() => this.saveNotes()}
            >
              Save Notes
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-1">
            <hr noshade="true" />

            {dropoffReport.overview && (
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>Cash on Hand</td>
                    <td>$ {dropoffReport.overview.cashOnHand}</td>
                  </tr>
                  <tr>
                    <td>Already Dropped Off</td>
                    <td>$ {dropoffReport.overview.cashDroppedOff}</td>
                  </tr>
                </tbody>
              </table>
            )}

            {dropoffReport.dropOffs && (
              <React.Fragment>
                <h2>Drop Off Log</h2>
                <table className="table table-striped">
                  <tbody>
                    {dropoffReport.dropOffs.map((dropoff, index) => (
                      <tr key={index}>
                        <td>{dropoff.epoc}</td>
                        <td>$ {dropoff.amount}</td>
                        <td>{dropoff.notes}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="col-sm-6 offset-sm-3 col-md-6 offset-md-3 text-center mb-3">
                  <button
                    type="submit"
                    className="btn btn-secondary btn-block"
                    onClick={() => this.printNotes()}
                  >
                    PRINT NOTES
                  </button>
                </div>
              </React.Fragment>
            )}

            {dropoffReport.customers && (
              <React.Fragment>
                <h2>Today's Customers</h2>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Time</th>
                      <th>Customer Name</th>
                      <th>Location</th>
                      <th>Umbrella Number</th>
                      <th>Payment Method</th>
                      <th>Rental Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dropoffReport.customers.map((customer, index) => (
                      <tr key={index}>
                        <td>{customer.epoc}</td>
                        <td>{customer.customerName}</td>
                        <td>{customer.locationsId}</td>
                        <td>{customer.umbrellaNumber}</td>
                        <td>{customer.paymentMethod}</td>
                        <td>$ {customer.price}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="6" align="right">
                        Daily Total: $ {dropoffReport.overview.todayTotal}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
NotesDropOff.propTypes = {
  dropoffReport: PropTypes.object.isRequired,
  printCode: PropTypes.object.isRequired,
  getDropoffReport: PropTypes.func.isRequired,
  getPrintNotes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dropoffReport: state.pages.dropoffReport,
  printCode: state.pages.printCode,
});

export default connect(mapStateToProps, {
  getDropoffReport,
  saveDropoffNotes,
  getPrintNotes,
})(NotesDropOff);
