import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Outlet, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "client/actions/auth";
import { ToastContainer } from "react-toastify";
import AppTemplateNav from "./AppTemplateNav";
import { ApiURL } from "../../config";
import FormInput from "components/cmation-formsbuilder";
import Loading from "./Loading";
import { SocketContext } from "../../App";
import "react-toastify/dist/ReactToastify.css";

const AppTemplate = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { searchTerm, setSearchTerm } = useContext(SocketContext);

  const [appVersion] = useState("");
  const [loading, setLoading] = useState(true);

  const authToken = localStorage.getItem("authToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const checkAuth = () => {
    let appVersion = searchParams.get("AV");
    setLoading(true);
    fetch(`${ApiURL}/auth/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.status) {
          navigate("/auth/login?AV=" + appVersion);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("authLogin Error", err);
      });
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    checkAuth();
  }, [props.auth]);

  return (
    <>
      {!loading && (
        <header>
          <nav className="navbar navbar-expand navbar-dark bg-dark text-light BorderShadow noprint">
            <div className="container-fluid">
              <AppTemplateNav
                appVersion={appVersion}
                userType={userInfo.userType}
                logout={props.logout}
              />
              <form
                method="post"
                id="searchscript"
                name="searchscript"
                onSubmit={() => null}
              >
                <FormInput
                  onChange={(value) => setSearchTerm(value)}
                  id="searchTerm"
                  name="searchTerm"
                  label=""
                  inputSize="sm"
                  type="search"
                  placeholder="Search"
                  value={searchTerm}
                  aria-label="Search"
                  hideBorder={true}
                  singleItem={true}
                >
                  {searchTerm && (
                    <button
                      className="btn btn-danger"
                      onClick={() => setSearchTerm("")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  )}
                </FormInput>
              </form>
            </div>
          </nav>
        </header>
      )}
      {loading ? (
        <Loading />
      ) : (
        <>
          {!children && <Outlet />}
          {children}
        </>
      )}

      {!loading && (
        <>
          <footer className="page-footer noprint mt-4">
            <div className="container-fluid font-small bg-light text-dark">
              <div className="container">
                <div className="row pt-3">
                  <div className="col-md-12 text-center">
                    <p>© Shade Patrol | All rights reserved</p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(AppTemplate);
